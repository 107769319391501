/**
Components CSS affects all components
 */

body {
  --gradient: #ebf1eb;
  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --primary: #144714;

  --secondary: #4f9b52;
  --gridResult: rgba(79, 155, 82, 0.3);
  --gray1: #032103;
  --gray2: #768276;
  --gray3: #a8b2a8;
  --gray4: #ebf1eb;
  --headerTextColor: #ebf1eb;
  --error: #eb5757;
  --background: white;
  --backgroundGray: #f9f9f9;
  --backgroundInput: white;
  --textColor: #032103;
  --textColorNeutral: #ebf1eb;
  --cardBackground: white;
  --headerColor: #768276;
  font-family: "Montserrat", sans-serif !important;
  background-color: var(--gray4) !important;
  --highlight: var(--primary) !important;
}

body.dark {
  --gradient: #282b28;
  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --primary: #144714;
  --secondary: #4f9b52;
  --gray1: #032103;
  --gray2: #cfe1cf;
  --gray3: #bfcebf;
  --gray4: #494b49;
  --error: #eb5757;
  --background: #313431;
  --backgroundGray: #3f3f3f;
  --backgroundInput: #727972;
  --textColor: white;
  --textColorNeutral: #ebf1eb;
  --headerColor: #032103;
  --cardBackground: #5a5d5a;
  font-family: "Montserrat", sans-serif !important;
  background-color: var(--gray4) !important;
  --highlight: var(--secondary) !important;
}

.voucherForm {
  max-height: 55vh;
  min-height: 55vh;
  overflow: auto;
  overflow-x: hidden;
}

.sidebarSpacing {
  height: 100vh;
}


.ui.sidebar {
  border-radius: 0.28571429rem !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 100vh !important;
}

.sidebarMenu {
  height: 100% !important;
  overflow: visible !important;
  z-index: 999999 !important;
}

.createProjectHeight {
  height: 60vh;
}

.popupBackground {
  background-color: var(--backgroundGray) !important;
  background: var(--backgroundGray) !important;
  color: var(--textColor) !important;
}

.historyHeight {
  max-height: 50vh !important;
}

.toggleLightMode {
  background-color: var(--primary) !important;
  color: var(--background) !important;
}

.toggleDarkMode {
  background-color: var(--gray4) !important;
  color: var(--primary) !important;
}

.buttonCancel {
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: 1px solid #768276 !important;
  color: #768276 !important;
}

.buttonDelete {
  background-color: var(--error) !important;
}

.buttonDelete__secondary {
  color: var(--error) !important;
  border: 1px solid var(--error) !important;
  background-color: transparent !important;
}

.buttonInfo__secondary {
  color: var(--info) !important;
  border: 1px solid var(--info) !important;
  background-color: transparent !important;
  font-size: 0.9em !important;
}

.buttonSecondary {
  background-color: var(--secondary) !important;
}

.buttonSecondary__transparent {
  color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  background-color: transparent !important;
}

.buttonBorder {
  border: 1px solid var(--headerTextColor) !important;
  background-color: transparent !important;
}

.profileButton {
  text-align: left !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: -5px !important;
  border-color: var(--secondary) !important;
  font-weight: 500 !important;
}

.buttonBorderPrimary {
  color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.buttonBorderPrimaryCalculate {
  color: var(--gray2) !important;
  border: 1px solid var(--gray2) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.buttonBorderTransparent {
  width: 9rem !important;
  display: inline-block !important;
  margin-left: 0.5rem !important;
  margin-right: 1rem !important;
  border: 1px solid var(--gray2) !important;
  background-color: transparent !important;
  color: var(--textColor) !important;
}

.calculateButton {
  margin-left: 10% !important;
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
  color: var(--gray2) !important;
}

.buttonBorderTransparentFluid {
  display: inline-block !important;
  width: 98% !important;
  border: 1px solid var(--gray2) !important;
  background-color: transparent !important;
  color: var(--textColor) !important;
  margin-bottom: 0.3rem !important;
}

.buttonImportFile {
  margin: auto !important;
  margin-top: 40% !important;
}

.header {
  font-family: "Montserrat", sans-serif !important;
}

.projectListHeaderBig {
  font-family: "Montserrat", sans-serif !important;
  max-height: 4rem !important;
  height: 4rem !important;
  display: block;
  color: var(--secondary) !important;
  font-size: 1.5rem !important;
  font-weight: 600;
  margin-bottom: 0.5rem;

  overflow: hidden;
}

.imageCard {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
}

.projectListDescription {
  font-family: "Montserrat", sans-serif !important;
  color: var(--gray3) !important;
  font-size: 1rem !important;
  font-weight: 400;
}

.bigCardDescription {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 3rem;
}

.projectList {
  padding-top: 1rem !important;
  padding-inline: 1rem !important;
  overflow-x: hidden;
  height: 77vh;
}

.projectListSmallCard {
  height: 78vh;
}

.mainContentBackground {
  background-color: var(--gray4) !important;
  overflow: auto !important;
}

.background {
  background-color: var(--background) !important;
}

.topbarBackground {
  background-color: var(--background) !important;
  width: auto !important;
}

.sidebarBackground {
  background-color: var(--background) !important;
}

.sidebarBackground.dark {
  background-color: var(--background) !important;
}

.projectCardSmall {
  box-shadow: 0 0 20px 0 rgba(43, 37, 63, 0.1) !important;
  margin: 1rem !important;
  padding: 1rem !important;
  width: 98% !important;
  height: 5rem !important;
}

.iconSpacing {
  margin-right: 1rem;
}

.menuIconSpacing {
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.menuIconSize {
  font-size: 1.5rem;
}

.menuIconSizeTrash {
  font-size: 1.5rem;
  margin-left: 1rem !important;
}

.secondary {
  color: var(--secondary) !important;
}

.image {
  background-color: transparent !important;
}

.projectImage {
  height: 20rem !important;
  width: 20rem;
}

.menuIconSize:hover {
  cursor: pointer;
}

.menuSelected {
  color: var(--secondary) !important;
}

/* Icon to collapse vertical sidebar */
.graySidebarIcon {
  color: var(--secondary) !important;
  font-size: 1.5rem !important;
  margin-right: -0.7rem !important;
  overflow: visible !important;
  z-index: 99999 !important;
  float: right !important;
  cursor: pointer;
}

.graySidebarIconHistory {
  margin-left: 2rem;
  margin-right: 0.5rem;
  color: #a8b2a8 !important;
  font-size: 1rem !important;
  cursor: pointer;
}

.graySidebarIconHistoryHeader {
  margin-right: 0.5rem;
  color: #a8b2a8 !important;
  font-size: 1rem !important;
}

.graySidebarIconHistoryHeaderActive {
  margin-left: 2rem;
  margin-right: 0.5rem;
  color: white !important;
  font-size: 1rem !important;
}

.graySidebarIconHistoryHeaderNameActive {
  margin-right: 0.5rem;
  color: white !important;
  font-size: 1rem !important;
}

.graySidebarIconLeft {
  color: #a8b2a8 !important;
  font-size: 1.5rem !important;
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  z-index: 99999 !important;
  float: left !important;
  cursor: pointer;
}

.statisticGridCheckbox {
  margin-left: 25% !important;
}

.graySidebarIconColapsed {
  color: var(--secondary) !important;
  font-size: 1.5rem !important;
  margin-top: 0rem !important;
  cursor: pointer;
}

.calculateModalSidebar {
  width: 100% !important;
}

.calculateModalTopbar {
  margin-bottom: 1rem !important;
}

.calculateModalSidebarList {
  height: 85vh;
  border: none !important;
  box-shadow: none !important;
}

.createProjectModalSidebarList {
  border: none !important;
  box-shadow: none !important;
}

.sidebarImageHeader {
  border: none !important;
}

.sidebarImage {
  width: 100% !important;
  height: auto !important;
}

.historyDescription {
  margin-left: 1rem !important;
  width: 50% !important;
  color: var(--primary) !important;
}

.historyDescription:hover {
  color: var(--primary) !important;
}

.accordionHeader {
  color: var(--gray2) !important;
  font-family: "Montserrat", sans-serif !important;
}

.historyToggleIcon {
  margin-left: 2rem !important;
  margin-right: 2px !important;
  color: var(--gray3);
}

.historyDate {
  margin-left: 5px !important;
  color: var(--primary) !important;
}

.historyHeader {
  cursor: pointer !important;
  margin-left: 1rem !important;
  padding-top: 1rem;
}

.projectInfoSidebar {
  height: 3rem !important;
  border: none transparent !important;

  /* Importante para icone de minimizar o vertical sidebar */
  z-index: 2 !important;
  overflow: visible !important;
}

.projectCardIcons {
  float: right;
  top: -0.5rem;
  padding-right: 1rem;
}

.closeProject {
  color: black !important;
  margin-right: 2rem !important;
  float: right;
  cursor: pointer;
}

.projectName {
  color: black !important;
  margin-left: 2rem !important;
}

.a {
  color: green !important;
}

.requiredNotSelected {
  background-color: sandybrown !important;
}

.totalRow {
  background-color: var(--gridResult) !important;
  font-weight: bold;
}

.text {
  color: var(--textColor) !important;
}

.searchInputNumber {
  width: 7rem !important;
  height: 1.7rem !important;
  border-radius: 1px !important;
  border: none !important;
}

.searchBox {
  width: 15rem !important;
}

.alertMessage {
  z-index: 11;
  position: fixed;
  right: 5rem;
  top: 2rem;
}

/*
ALERTS
 */

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed !important;
  z-index: 999999;
}

.top-right {
  position: fixed !important;
  top: 1%;
  right: 1%;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  position: fixed;
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  background: #fff;
  transition: 0.3s ease;
  position: fixed !important;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 30px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 12rem;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 40px;
}

.notification-message {
  margin: 0;
  text-align: left;
  height: 40px;
  margin-left: -1px;
  word-wrap: break-word !important;
  display: block !important;
  position: fixed;
  max-width: 300px;
  line-break: auto;
}

.notification-image {
  float: left;
  margin-right: 15px;
}

.notification-image img {
  width: 30px;
  height: 30px;
}

.toast {
  position: fixed;
  height: 300px !important;
  width: 365px;
  color: #fff;
  padding: 20px 15px 10px 10px;
}

.notification-container button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-weight: 700;
  color: #fff;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 1400px) {
  .openSidebar {
    width: calc(100% - 25vh) !important;
  }
}

@media only screen and (max-width: 1400px) {
  .openSidebar {
    width: calc(100% - 35vh) !important;
  }
}

.openSidebar {
  overflow: hidden !important;
}

.closedSidebar {
  width: 96% !important;
  margin-left: 5rem !important;
  overflow-x: visible;
}

.noSidebar {
  width: 100% !important;
}

.ui.secondary.pointing.menu .active.item:not(.calculationMenuSidebar) {
  border-color: var(--primary) !important;
  box-shadow: inset 0 -0.2rem 0 0 var(--primary) !important;
}

.calculationMenuSidebar.active.item {
  border-color: transparent !important;
  height: 3rem;
  font-size: 1rem !important;
  padding-top: 1rem !important;
  box-shadow: none !important;
}

.calculationMenuSidebar.item {
  border-color: transparent !important;
  height: 3rem;
  font-size: 1rem !important;
  padding-top: 1rem !important;
  padding-left: 10% !important;
}

.gridCalculate {
  background-color: var(--backgroundGray);
}

.createProjectMenuSidebar.item {
  border-color: transparent !important;
  height: 3rem;
  font-size: 1rem !important;
  padding-top: 1rem !important;
  padding-left: 10% !important;
  box-shadow: none !important;
}

.secondStepButton {
  word-wrap: break-word !important;
  display: block !important;
  width: 12rem;
  line-break: auto;
}

.borderlessButton {
  background-color: transparent !important;
  color: var(--primary) !important;
  display: inline-block !important;
}

.messageCard {
  text-align: center !important;
  color: var(--gray4) !important;
  margin: auto !important;
  background-color: var(--info) !important;
}

.messageCardSuccess {
  text-align: center !important;
  color: var(--gray4) !important;
  margin: auto !important;
  background-color: var(--secondary) !important;
}

.importCardHeader {
  display: inline-block !important;
  color: var(--primary) !important;
  padding-left: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.iconLeft {
  margin-right: 0.4rem !important;
  float: left !important;
  display: inline-block !important;
  color: var(--textColor) !important;
}

.gridNumberInput {
  flex: 1 0 auto !important;
  width: 100% !important;
  height: 1.5vh !important;
  line-height: 1rem !important;
  border: none;
  border-radius: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  outline: 0 !important;
}

.logoCores {
}

.profileImage {
  display: inline-block;
  color: white;
  width: 2rem;
  height: 2rem;
}

.breadcrumbProject {
  margin-top: 10px !important;
}

.projectInfoHeader {
  margin: 6px !important;
  margin-left: 3.5rem !important;
}

.projectInfoDivider {
  border-left: 1px solid var(--secondary) !important;
  border-radius: 0;
  margin: 0 1rem 0 3rem !important;

  height: 2.7rem !important;
}

.closeProjectButton {
  margin-right: 2rem !important;
  cursor: pointer !important;
}

.postsContainer {
  margin-top: 1rem;
  overflow: scroll;
  overflow-x: hidden;
  height: 35.5%;
}

.permissionList {
  height: 30vh;
  overflow-y: auto;
}

.iconShareProject {
  float: right;
  font-size: 1.5rem;
  color: var(--gray2);
  margin-top: -1rem;
  margin-left: 1rem;
}

.gridNumberInput:focus {
  flex: 1 0 auto !important;
  width: 100% !important;
  line-height: 1rem !important;
  border: none;
  margin: 0 !important;
  background-color: transparent !important;
  outline: 0 !important;
}

.pageLoader {
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  /* Negative half of height. */
  margin-left: -250px;
  /* Negative half of width. */
  z-index: 99999 !important;
  background-color: transparent !important;
}

.animatedSVG {
  height: 100% !important;
  width: 100% !important;
  margin: auto !important;
}

.animateDots {
  height: 40% !important;
  width: 40% !important;
  margin-left: 30% !important;
}

.hidden {
  display: none !important;
}

.gridItalic {
  font-style: italic;
}

.text__header {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  color: rgba(20, 71, 20, 0.87) !important;
  font-size: 24px;
}

.text__form__title {
  font-family: "Montserrat";
  font-weight: 700;
  color: rgba(20, 71, 20, 0.87);
  font-size: 16px;
}

.text__header__licence {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.87) !important;
  font-size: 16px !important;
}

.text__header__secondary {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  color: rgba(20, 71, 20, 0.87) !important;
  font-size: 16px !important;
}

.text__label {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  color: rgba(20, 71, 20, 0.6);
  font-size: 14px !important;
}

.text__label__form > label {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.65) !important;
  font-size: 12px !important;
}

.text__secondary {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  color: rgba(20, 71, 20, 0.6) !important;
  font-size: 14px !important;
  line-height: 1.5em;
}

.text__terciary {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  color: rgba(20, 71, 20, 0.8) !important;
  font-size: 14px !important;
  line-height: 1.5em;
}

.text__link {
  color: rgba(79, 155, 82, 1) !important;
}

.text__highlight {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.6) !important;
  font-size: 14px !important;
  text-transform: uppercase;
}

.menuVertical__header {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.87) !important;
  font-size: 14px;
}

.menuVertical__item {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  color: rgba(20, 71, 20, 0.6) !important;
  font-size: 14px !important;
}

.menuVertical__item__icon {
  color: rgba(20, 71, 20, 0.6) !important;
}

.active .menuVertical__item__icon {
  color: var(--textColorNeutral) !important;
}

.active .menuVertical__item {
  color: var(--textColorNeutral) !important;
}

/*Menu vertical collapsed  */
.ui.secondary.vertical:not(.visible)
  .ui.divider:not(.vertical):not(.horizontal).menuVertical__divider {
  border-top: 0.5px solid rgba(20, 71, 20, 0.1) !important;
  border-bottom: hidden !important;
  margin: 20px 8px !important;
}

/* Menu vertical opened*/
.ui.secondary.vertical.visible
  .ui.divider:not(.vertical):not(.horizontal).menuVertical__divider {
  border-top: 0.5px solid rgba(20, 71, 20, 0.1) !important;
  margin: 20px 0px !important;
}

/* Menu vertical collapsed */
.ui.secondary.vertical:not(.visible)
  .ui.divider:not(.vertical):not(.horizontal).menuVertical__divider__top {
  border-top: 0.5px solid rgba(20, 71, 20, 0.1) !important;
  border-bottom: hidden !important;
  margin: 0px 8px 20px !important;
}

/* Menu vertical opened */
.ui.secondary.vertical.visible
  .ui.divider:not(.vertical):not(.horizontal).menuVertical__divider__top {
  border-top: 0.5px solid rgba(20, 71, 20, 0.1) !important;
  margin: 0px 0px 20px !important;
}

.item {
  overflow: visible !important;
  z-index: 99999 !important;
}

.modal__header {
  font-family: "Montserrat" !important;
  font-size: 18px !important;
  font-weight: 700;
  color: rgba(20, 71, 20, 0.87) !important;
}

.modal__label {
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(20, 71, 20, 0.6) !important;
}

.message .header {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.message p {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

/* DO NOT REMOVE - Necessary so date picker ag grid component will be visible */
.ag-root-wrapper {
  overflow: visible !important;
}

.ag-row .ag-cell {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: rgba(20, 71, 20, 0.87) !important;
  overflow: hidden !important;
}

.ag-header-cell-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.87) !important;
}

.userProfile .image {
  background: #ebf1eb !important;
  border: 2px solid white !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  max-width: 150px !important;
  height: 150px !important;
  margin: 4em auto 0px;
  object-fit: cover;
}

.ui.menu {
  font-family: "Montserrat" !important;
  align-items: center;
}

.ui.label {
  font-weight: 600 !important;
}

.ui.fluid.search .results {
  max-height: 30vh;
  overflow-y: scroll;
}

.ui.comments .comment .metadata {
  margin-left: 0em !important;
}
.labelRequiredIcon::after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
  line-height: 1.2em;
}

.radioLabel * label {
  padding-left: 1.6em !important;
  padding-right: 1.6em !important;
  font-size: 1em !important;
  font-weight: 400;
}
