@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";
@import "~ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine((
    // use theme parameters where possible
          alpine-active-color: #4F9B52
  ));
  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-family: 'Montserrat', sans-serif !important;
  }
  .ag-cell{
    border-right: 1px solid rgba(166, 166, 166, 0.53) !important;
  }

}

.ag-theme-balham-dark{
  @include ag-theme-balham-dark((
    // use theme parameters where possible
          balham-active-color: #4F9B52
  ));
  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--background);
  }
  .ag-cell{
    font-family: 'Montserrat', sans-serif !important;
    border-right: 1px solid rgba(166, 166, 166, 0.53) !important;
    font-size: 1rem;
  }

}


.ag-theme-balham {
  @include ag-theme-balham((
    // use theme parameters where possible
          balham-active-color: #4F9B52
  ));
  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-family: 'Montserrat', sans-serif !important;
  }
  .ag-cell{
    border-right: 1px solid rgba(166, 166, 166, 0.53) !important;
    font-size: 1rem;
  }

}
