/*
   This CSS is responsible for adjusting the screen on smaller resolutions
 */
 /**
Components CSS affects all components
 */

html {
  overflow-x: hidden !important;
}

body {
  overflow-y: hidden !important;

  --gradient: #ebf1eb;
  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --primary: #144714;

  --secondary: #4f9b52;
  --gridResult: rgba(79, 155, 82, 0.3);
  --gray1: #032103;
  --gray2: #768276;
  --gray3: #a8b2a8;
  --gray4: #ebf1eb;
  --headerTextColor: #ebf1eb;
  --error: #eb5757;
  --background: white;
  --backgroundGray: #f9f9f9;
  --backgroundInput: white;
  --textColor: black;
  --textInverted: white;
  --textColorNeutral: #ebf1eb;
  --cardBackground: white;
  --headerColor: #768276;
  font-family: "Montserrat", sans-serif !important;
  background-color: var(--gray4) !important;
  --highlight: var(--primary) !important;
}

body.dark {
  --gradient: #282b28;
  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --primary: #144714;
  --secondary: #4f9b52;
  --gray1: #032103;
  --gray2: #cfe1cf;
  --gray3: #bfcebf;
  --gray4: #494b49;
  --error: #eb5757;
  --background: #313431;
  --backgroundGray: #3f3f3f;
  --backgroundInput: #727972;
  --textColor: white;
  --textInverted: black;
  --textColorNeutral: #ebf1eb;
  --headerColor: #032103;
  --cardBackground: #5a5d5a;
  font-family: "Montserrat", sans-serif !important;
  background-color: var(--gray4) !important;
  --highlight: var(--secondary) !important;
}


@media only screen and (min-width:1400px) {
  .openSidebar{
    width: calc(100% - 25vh) !important;
  }
}

@media only screen and (max-width: 1400px) {
  .openSidebar{
    width: calc(100% - 25vh) !important;
  }

  body{
    overflow-y:  visible !important;
  }

  html {
    overflow-x: visible !important;
  }

  .closedSidebar{
    width:93% !important;
    margin-left: 5rem !important;
    overflow-x: visible;
  }

  .projectListContainer{
    height: 30vh !important;
    left: 0rem;
  }

  .projectListSmallCard{
    height: 65vh !important;
  }

  .calculateModalZoom{
    min-height: 60vh !important;
  }

  .historyZoom{
    height: 27vh !important;
  }

  .tableZoom{
    min-height: 80vh !important;
    overflow: auto;
  }

  .postsContainer{
    height: 15.5% !important;
  }

  .sidebarSpacing{
    height: 85vh !important;
  }

  .createProjectHeight{
    min-height: 75vh !important;
  }

  .voucherForm{
    max-height: 55vh;
    min-height: 55vh;
    overflow: auto;
    overflow-x: hidden;
  }

}

i.close.icon::before {
  cursor: pointer;
  position: absolute;
  font-size: 25px;
  color: black;
  left: -30px;
  top: 45px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* SEMANTIC */

.ui.secondary.menu .active.item {
  color: var(--gray4) !important;
  border-radius: 0!important;
  box-shadow: inset 0.5rem 0 0 0 var(--primary);
  background-color: var(--secondary) !important;
}

/* Sidebar vertical menu when collapsed */
.ui.secondary.menu:not(.visible) .active.item {
  width: 75px !important;
}

.ui.menu{
  background-color: var(--background);

  /* Importante para seta de minimizar menu */
  z-index: 9999 !important;
  overflow: visible !important;
}
.item {
  overflow: hidden !important;
}

.ui.secondary.menu .active.item.activeCalc {
  color: var(--backgroundGray) !important;
  border-radius: 0!important;
  box-shadow: none !important;
  background-color: var(--secondary) !important;
}



.ui.secondary.menu .item .historyHeader {
  color: var(--gray2) !important;
  border-radius: 0!important;
  box-shadow: none !important;
}


.ui.visible.push.sidebar{
  background: var(--backgroundGray) !important;
}

.ui.tab.active{
  background: var(--backgroundGray) !important;
}

.ui.tabular.menu .active.item{
  background: var(--secondary) !important;
  color: white !important;
}

.ui.table th{
  background: var(--backgroundGray) !important;
  color: var(--textColor) !important;
}

.ui.table{
  background: var(--backgroundGray) !important;
  color: var(--textColor) !important;
}


.ui.secondary.menu .item{
  color: var(--gray2) !important;
}

.ui.secondary.menu .item.menuIcon{
  color: var(--textColorNeutral) !important;
}

.ui.secondary.menu .item.profileIcon:hover{
  background-color: var(--secondary) !important;
}

.imageProfile{
  padding: 0 !important;
  border: 2px solid white !important;
  object-fit: cover !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  height: 40px !important;
  width: 40px !important;
}

.imageProfile.popupMenu {
  height: 50px !important;
  width: 50px !important;
  margin-top: -5px !important;
}

.ui.secondary.menu .item.topbarMenu{
  color: var(--textColorNeutral) !important;
}

.ui.secondary.menu .item.calculationMenuSidebar{
  margin-left: -1rem !important;
  color: var(--gray2) !important;
}

.ui.secondary.menu .item.calculationMenuHeader{
  color: var(--secondary) !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  text-align: center;
}
.ui.secondary.menu .item.calculationMenuHeader:hover{
  color: var(--secondary) !important;
  font-size: 1.2rem !important;
  background-color: transparent !important;
}


.ui.secondary.menu .active.item.calculationMenuSidebar{
  color: var(--gray4) !important;
}

.ui.secondary.menu .item.menuText{
  color: var(--gray2) !important;
}

.ui.secondary.menu .active.item.menuText{
  color: var(--headerTextColor) !important;
}

.ui.secondary.menu .item.menuText:hover{
  color: var(--gray2) !important;
  background-color: var(--gray4) !important;
}

.ui.secondary.vertica.menu>.item{
  color: var(--gray2) !important;
}

.ui.secondary.menu.topbarBackground {
  background: linear-gradient(90deg, var(--gray4) 0%, rgba(79,155,82,1) 50%, rgba(18,83,52,1) 100%);
  box-shadow: 0 8px 6px -6px rgba(20, 71, 20, 0.25) !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: sticky !important;
  z-index: 1 !important;
}

.ui.secondary.menu .item:hover{
  background-color: var(--gray4) !important;
  color: var(--textColor) !important;
}

.ui.card>.content>.header{
  color: var(--secondary) !important;
}

.ui.card .meta *{
  color: var(--gray2) !important;
}

.ui.card>.content>.description{
  color: var(--gray2) !important;
}

.ui.card>.content a:not(.ui){
  color: var(--gray2) !important;
}


.ui.modal>.header{
  background-color: var(--background) !important;
}
.ui.modal>.content{
  background-color: var(--background) !important;
}
.ui.modal>.actions{
  background-color: var(--background) !important;
}
.ui.fullscreen.modal {
  background-color: var(--background) !important;
  top: 0 !important;
  left: 2.4% !important;
  width: 95% !important;
  height: 80vh !important;
}

h3 {
  color: rgba(20, 71, 20, 0.87) !important;
}

h5{
  color: rgba(20, 71, 20, 0.87) !important;
}

.ui.form .field>label{
  color: rgba(20, 71, 20, 0.6) !important;
}

.ui.large.modal>.header{
  color: rgba(20, 71, 20, 0.87) !important;
}
.ui.fullscreen.modal>.header{
  color: rgba(20, 71, 20, 0.87) !important;
}
.ui.small.modal>.header{
  color: rgba(20, 71, 20, 0.87) !important;
}


.ui.secondary.menu .item.mainPageLogo:hover{
  background-color: transparent !important;
}

.ui.secondary.menu .item.noHover:hover{
  background-color: transparent !important;
}


.ui.secondary.vertical.menu>.item{
  margin: 0 !important;
}

.ui.secondary.vertical.menu>.item{
  margin: 0 !important;
}

.ui.secondary.vertical.menu>.item .historyItem{
  margin: 0 !important;
  padding: 0 !important;
}

.ui.secondary.vertical.menu .historyCalc{
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 4px !important;
}
.ui.divider{
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.ui.card{
  background-color: var(--cardBackground);
}

.ui.search .prompt{
  height: 1.7rem !important;
  border-radius: 1px !important;
  border: none !important;
}


.ui.steps .step.active{
  background-color: var(--primary) !important;
}

.ui.steps .step.active:after{
  background-color: var(--primary) !important;
}

.ui.steps .title{
  color: var(--primary) !important;
}

.ui.steps .iconStepColor{
  color: var(--primary) !important;
}

.ui.steps .step.active .title{
  color: var(--gray4) !important;
}

.ui.steps .step.active .iconStepColor{
  color: var(--gray4) !important;
}


a.item{
  color: var(--textColor) !important;
}

.ui.checkbox label{
  color: var(--textColor) !important;
}


input{
  background-color: var(--backgroundInput) !important;
}

.dropdown{
  background-color: var(--backgroundInput) !important;
}
.dropdown:hover{
  background-color: var(--backgroundInput) !important;
}

i.dropdown.icon{
  background-color: transparent!important;
}

.ui.menu .ui.dropdown .menu .active.item:hover{
  background-color: var(--backgroundGray) !important;
  color: var(--textColor) !important;
}

.button{
  font-family: 'Montserrat', sans-serif !important;
  background-color: var(--secondary) !important;
  color: var(--background) !important;
}

.ui.basic.secondary.button.profileButton{
  box-shadow: none !important;
  color: rgba(18, 83, 52, 0.9) !important;
  background-color: var(--backgroundInput) !important;
}

.ui.basic.secondary.button.profileButton i {
  margin: 0 1em 0 -0.21428571em !important;
}

.ui.basic.secondary.button.profileButton:hover{
  background-color: var(--gray4) !important;
  color: var(--highlight) !important;
}

.ui.popup{
  background-color: var(--backgroundInput);
}

.ui.card.profileCard{
  box-shadow: none;
  background-color: var(--backgroundInput) !important;
  padding: 0px !important;
}

.ui.card.profileCard img {
  border: 1.2px solid rgba(79, 155, 82, 0.6) !important;
  padding: 2px !important;
  margin-left: 8px !important;
  margin-right: 14px !important;
}

.ui.card.profileCard>.content{
  padding: 8px 0px 0px !important;
  background-color: var(--backgroundInput) !important;
}

.ui.card.profileCard .header {
  font-weight: 700;
  font-size: 16px !important;
  color: rgba(18, 83, 52, 0.87) !important;
  margin-top: 0px !important;
  line-height: 1.28571429em;
}

.ui.card.profileCard .description {
  margin: 10px 0px 0px !important;
}

.ui.card.profileCard .meta{
  color: rgba(18, 83, 52, 0.65) !important;
  font-size: 12px !important;
}
.ui.card.profileCard .divider {
  border-top: 1px solid rgba(18, 83, 52, 0.1) !important;
  border-bottom: 0px !important;
  margin: 2px -14px 8px !important;
}

.ui.bottom.right.popup:before{
  background-color: var(--backgroundInput) !important;
}

.ui.menu:not(.vertical) .item>.button:has(.button--secondary) {
  font-family: 'Montserrat'!important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
  padding: 11px 21px !important;
  background-color: transparent !important;
  color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
}

.button--primary {
  font-family: 'Montserrat'!important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
  padding: 11px 21px !important;
  background-color: var(--secondary) !important;
  color:  white !important;
}

.button--secondary {
  font-family: 'Montserrat'!important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
  padding: 11px 21px !important;
  background-color: transparent !important;
  color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
}

.button--terciary {
  font-family: 'Montserrat'!important;
  font-weight: 600 !important;
  font-size:0.9em !important;
  padding: 11px 21px !important;
  background-color: transparent !important;
  color: var(--info) !important;
  border: 1px solid var(--info) !important;
}

.button--delete {
  font-family: 'Montserrat'!important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
  padding: 11px 21px !important;
  background-color: transparent !important;
  color: var(--error) !important;
  border: 1px solid var(--error) !important;
}

.searchItem{
  padding: 0 !important;
}

.ui.menu .searchItem .item:before{
  height: 80% !important;
  margin-top: 10px !important;
}

.ui.menu:not(.vertical) .item>.button{
  font-family: 'Montserrat', sans-serif !important;
}

.ui.secondary.menu .active.item:hover:not(.calculationMenuTopbar) {
  color: var(--gray4) !important;
  border-left: 0!important;
  box-shadow: inset 0.5rem 0 0 0 var(--primary);
  background-color: var(--secondary) !important;
}


.ui.secondary.menu.calculateModalSidebarList{
  border-color: transparent !important;
  box-shadow: transparent !important;
  padding: 0 !important;
}

.item::before{
  top: 0!important;
  bottom: 0!important;
}

.ui.dropdown .menu .selected.item{
  background-color: var(--backgroundInput);
}
.ui.dropdown .menu .item{
  background-color: var(--backgroundGray);
}
.ui.dropdown .menu .item:hover{
  background-color: var(--backgroundInput);
}

.ui.form .field .ui.input input, .ui.form .fields .field .ui.input input{
  color: var(--textColor)
}

.ui.menu .ui.dropdown .menu>.item{
  background-color: var(--backgroundGray) !important;
}

.ui.menu .ui.dropdown .menu>.item.active{
  background-color: var(--backgroundInput) !important;
}

.ui.menu .ui.dropdown .menu>.item:hover{
  background-color: var(--backgroundInput) !important;
}

.ui.card>.content, .ui.cards>.card>.content{
  background-color: var(--background) ;
}

.ui.card>.content a.readMore:not(.ui){
  float: right !important;
  color: var(--secondary) !important;
}

.label.blockedProjectInfo{
  opacity: 100 !important;
}

.ui.form textarea{
  background-color: var(--backgroundInput) !important;
}

.ui.form.formProjectBackground{
  background-color: var(--background) !important;
}

input {
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  font-weight: 600;
}

.ui.checkbox label {
  font-family: 'Montserrat' !important;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  color: rgba(20, 71, 20, 0.6) !important;
}




