.loginContainer{
  padding: 5% 0;
  margin: auto;
  width: 20%;
  min-width: 450px;
}

.loginDiv{
  height: 70%;
}
.loginButton{
  width: 100%;
  background: #2D703E !important;
  color: #fff !important;
}

.helpButtonLogin{
  position:fixed;
  right:10px;
  bottom:20px;
}
